import {
  getPaddingOnBody,
  getPaddingFromBody,
  getScrollbarWidth,
} from "../../utils/utils";

const menu = document.querySelector(".menu");

if (menu) {
  const burger = menu.querySelector(".menu__burger");

  if (burger) {
    burger.addEventListener("click", () => {
      if (menu.classList.contains("menu--active")) {
        menu.classList.remove("menu--active");
        getPaddingFromBody();
      } else {
        menu.classList.add("menu--active");
        getPaddingOnBody();
      }
    });
  }
}

const menuLists = document.querySelectorAll('.menu__list');
menuLists.forEach((menuList) => {
  const toggle = menuList.querySelector('.menu__list-toggle');
  const inner = menuList.querySelector('.menu__list-inner');
  if (inner && toggle) {
    toggle.addEventListener('click', (evt) => {
      evt.preventDefault();

      if (!menuList.classList.contains('menu__list--active')) {
        menuList.classList.add('menu__list--active');
        inner.style.maxHeight = `${inner.scrollHeight}px`;
      } else {
        menuList.classList.remove('menu__list--active');
        inner.style.maxHeight = '';
      }
    });
  }
});

window.addEventListener('load', () => {
  const activemenuLists = document.querySelectorAll('.menu__list--active');
  activemenuLists.forEach((menuList) => {
    const inner = menuList.querySelector('.menu__list-inner');
    if (inner) {
      inner.style.maxHeight = `${inner.scrollHeight}px`;
    }
  });
});
